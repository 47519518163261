@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  html {
    // Flatstudio uses 360 as a base for mobile design, we count it as primary for landings too
    font-size: 4.44444vw; // 16px for mobiles with 360px screen width

    @media (min-width: 569px) {
      font-size: 16px;
    }

    @media (min-width: 1981px) {
      font-size: 16px;
    }
  }

  html,
  body {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    margin: 0;
    padding: 0;
  }

  html,
  body {
    min-height: 100dvh;
  }

  body {
    font-weight: 400;
    font-family: 'Poppins', var(--font-poppins), system-ui, sans-serif;
    text-rendering: optimizeLegibility;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

:focus-visible {
  outline: none;
}

.focus-visible {
  :focus-visible {
    outline: auto;
    outline-offset: 2px;
    //-moz-outline-radius: 4rem;
  }
}

// this doesn't work in @layer base
body {
  &.body-scroll-frozen {
    overflow: hidden;
  }
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
}

@layer components {

  .w-container {
    @apply w-full px-4;

    @screen sm {
      @apply px-3vw;
    }

    @screen xxl {
      max-width: 1440px;
      @apply px-4 mx-auto;
    }
  }

  .bone {
    position: relative;
    overflow: hidden;
    @apply bg-black/25;
    @apply animate-pulse;
  }

  .word-spacing-max {
    word-spacing: 9999px;
  }

  .word-spacing-normal {
    word-spacing: normal;
  }

  .text-land-h1,
  .text-land-h2,
  .text-land-h5 {
    font-weight: 700;
    text-transform: uppercase;
  }

  .text-land-h1 {
    letter-spacing: -0.06em;
    font-size: 4.375rem; // 70
    line-height: 3.9375rem; // 63

    @screen sm {
      font-size: 7.1875rem; // 115
      line-height: 6.125rem; // 98
    }
  }

  .text-land-h2 {
    letter-spacing: -0.04em;
    font-size: 2.75rem; // 44
    line-height: 2.5rem; // 40

    @screen sm {
      font-size: 4.375rem; // 70
      line-height: 3.9375rem; // 63
    }
  }

  .text-land-h3 {
    font-weight: 500;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-size: 1.5rem; // 24
    line-height: 1.35rem; // 21.6

    @screen sm {
      font-size: 2.25rem; // 36
      line-height: 2.925rem; // 46.8
    }
  }

  .text-land-h4 {
    font-size: 1.25rem; // 20
    line-height: 1.625rem; // 26

    @screen sm {
      font-size: 1.75rem; // 28
      line-height: 2.275rem; // 36.4
    }
  }

  .text-land-h5 {
    letter-spacing: 0.02em;
    font-size: 1rem; // 16
    line-height: 1rem; // 16

    @screen sm {
      font-size: 1.25rem; // 20
      line-height: 1.25rem; // 20
    }
  }

  .text-land-h6 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.6875rem; // 11
    line-height: 0.6875rem; // 11
    letter-spacing: 0.02em;

    @screen sm {
      font-size: 0.75rem; // 12
      line-height: 0.75rem; // 12
      letter-spacing: 0.045em;
    }
  }

  .text-land-body-1 {
    font-size: 0.875rem; // 14
    line-height: 1.225rem; // 19.6

    @screen sm {
      font-size: 1.25rem; // 20
      line-height: 1.5625rem; // 25
    }
  }

  .text-land-body-2 {
    font-size: 0.75rem; // 12
    line-height: 1.05rem; // 16.8
    letter-spacing: 0.04em;

    @screen sm {
      font-size: 1.125rem; // 18
      line-height: 1.35rem; // 21.6
      letter-spacing: 0.02em;
    }
  }

  .text-land-body-3 {
    font-size: 0.6875rem; // 11
    line-height: 0.825rem; // 13.2
    letter-spacing: 0.05em;

    @screen sm {
      font-size: 0.9375rem; // 15
      line-height: 1.125rem; // 18
      letter-spacing: 0.02em;
    }
  }

  // ---- Landing Buttons

  .btn-land-white {
    @apply bg-white text-black;
    @apply hocus:bg-white/70;
  }

  .btn-land-black {
    @apply bg-black text-white;
    @apply hocus:bg-black/70;
  }

  .btn-land-gradient-ocean {
    @apply bg-gradient-ocean-blue text-white;
    @apply hocus:bg-brand-50;
  }

  .btn-land-ocean-20 {
    @apply bg-brand-50/20 uppercase text-deep-blue;
    @apply hocus:bg-brand-50 hocus:text-white;
  }

  .btn-land-white,
  .btn-land-black,
  .btn-land-gradient-ocean,
  .btn-land-ocean-20 {
    @apply text-center font-poppins rounded-full transition-all;

    &[disabled], &.disabled {
      @apply pointer-events-none cursor-default opacity-70;
    }
  }

  .btn-land-size-40 {
    @apply h-10 px-4;
    font-size: 0.9375rem; // 15
    font-weight: 600;
    line-height: 1 !important;
    letter-spacing: 0.03em;
  }

  .btn-land-size-48 {
    @apply h-12 px-6 text-land-body-2;
    font-weight: 600 !important;
    line-height: 1 !important;

    .btn-icon {
      @apply h-5;
    }
  }

  .btn-land-size-56 {
    @apply h-14 px-7 text-land-h5;

    .btn-icon {
      @apply h-6;
    }
  }

  // ---- App Buttons

  .btn-master-primary {
    @apply relative overflow-hidden uppercase text-white font-bold;

    &::before {
      @apply absolute top-0 -z-10 right-0 bg-grey-90 size-full;
      content: '';
    }

    &::after {
      @apply absolute top-0 -z-10 right-0 bg-gradient-master-primary size-full transition-all duration-300;
      content: '';
    }

    &:hover,
    &:focus-visible {

      &::after {
        transform-origin: left center;
        transform: translateX(-100%) rotateY(45deg);
        opacity: 0;
      }
    }
  }

  .btn-primary {
    @apply bg-brand-50 text-white font-semibold;
    @apply hocus:bg-none hocus:bg-grey-90;
  }

  .btn-secondary {
    @apply bg-grey-90 font-semibold text-white;

    @apply hocus:bg-none hocus:bg-brand-50;
  }

  .btn-master-primary,
  .btn-primary,
  .btn-secondary,
  .btn-tertiary {
    @apply text-center font-poppins rounded-full transition-all;
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: -2%;

    &[disabled], &.disabled {
      @apply pointer-events-none cursor-default text-grey-70 bg-bg-10;
    }
  }

  .btn-tertiary {
    @apply bg-white font-semibold text-grey-90 shadow-lvl1 border border-bg-30;
    @apply hocus:bg-grey-90 hocus:border-grey-90 hocus:text-white;

    &[disabled], &.disabled {
      @apply bg-bg-20;
    }
  }

  .btn-quaternary {
    @apply text-center text-grey-70 uppercase bg-white font-semibold border border-bg-30 rounded-full transition-all;
    @apply hocus:text-grey-90 hocus:bg-bg-20 hocus:border-bg-20;

    font-size: 0.875rem;
    line-height: 0.875rem;
    letter-spacing: -1%;

    @media (hover: hover) {
      &:hover {
        @apply text-grey-90 bg-bg-20 border-bg-20;

        .btn-icon {
          @apply text-grey-70;
        }
      }
    }


    &:focus-visible {
      @apply text-grey-90 bg-bg-20 border-bg-20;

      .btn-icon {
        @apply text-grey-70;
      }
    }

    &[disabled], &.disabled {
      @apply pointer-events-none cursor-default text-grey-60;
    }
  }

  .btn-icon {
    @apply h-4;

    * ~ &,
    & ~ * {
      @apply ml-2;
    }
  }

  .btn-size-44 {
    @apply h-11 px-4;

    .btn-icon {
      @apply h-5;
    }
  }

  .btn-size-32 {
    @apply h-8 px-3;
  }

  .btn-size-20 {
    @apply h-5 px-1.5;

    .btn-icon {
      * ~ &,
      & ~ * {
        @apply ml-0.5;
      }
    }
  }

  .link-brand {
    @apply text-brand-50 hocus:text-grey-90 transition;
  }

  .tab {
    @apply relative pb-3.5 text-body-18 font-semibold text-grey-80 outline-0;
    @apply hocus:text-grey-90 ui-selected:text-grey-90;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      border-radius: 4px 4px 0 0;
      transform: scaleX(0);
      @apply bg-brand-50 transition;
      content: '';
    }

    .focus-visible &:focus-visible {
      outline: none;
      @apply text-brand-50;

      &::after {
        transform: scaleX(1);
        opacity: 0.5;
      }
    }

    &.active,
    &[data-headlessui-state~="selected"] {
      @apply text-grey-90 cursor-default;

      &::after {
        transform: scaleX(1);
        opacity: 1 !important;
      }
    }
  }

  .grid-cols-2-center {
    grid-template-columns: repeat(4, 1fr);

    & > * {
      grid-column: span 2;

      /* Dealing with single orphan */
      &:last-child:nth-child(2n - 1) {
        grid-column-end: -2;
      }

      /* Dealing with 2 orphan items */
      &:last-child:nth-child(3n - 1) {
        grid-column-end: unset;
      }

      &:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: unset;
      }

      /* Dealing with single orphan */
      &:last-child:nth-child(3n - 2) {
        grid-column-end: unset;
      }
    }
  }

  .grid-cols-3-center {
    grid-template-columns: repeat(6, 1fr);

    & > * {
      grid-column: span 2;

      /* Dealing with 2 orphan items */
      &:last-child:nth-child(3n - 1) {
        grid-column-end: -2;
      }

      &:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: 4;
      }

      /* Dealing with single orphan */
      &:last-child:nth-child(3n - 2) {
        grid-column-end: 5;
      }

      &:last-child:nth-child(2n - 1) {
        grid-column-end: unset;
      }
    }
  }

  .mt-top-notice {
    .top-notice-visible & {
      margin-top: var(--top-notice-h) !important;
    }
  }

  .pt-top-notice {
    .top-notice-visible & {
      padding-top: var(--top-notice-h) !important;
    }
  }

  .min-h-full-top-notice {
    min-height: calc(100% - var(--top-notice-h));
  }
}

@layer utilities {

  // update to use the only class instead of duplication "h-screen h-dvh"
  .h-dvh {
    height: 100vh;
    height: 100dvh;
  }

  .min-h-dvh {
    min-height: 100vh;
    min-height: 100dvh;
  }

  .stops-4-cylinder {
    --tw-gradient-stops: transparent 4%, black 24%, black 76%, transparent 95%;
  }
}

//:root {
//  --font-gilroy: 'Gilroy';
//}

h1, h2, h3, h4, h5, h6,
.text-land-h1,
.text-land-h2,
.text-land-h3,
.text-land-h4,
.text-land-h5,
.text-land-h6 {
  @apply font-poppins;
}

//.text-land-body-1,
//.text-land-body-2,
//.text-land-body-3 {
//  @apply font-gilroy;
//}

[role="tabpanel"] {
  outline: none;
}

.app-layout {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 4rem 0 1fr;

  @screen xl {
    grid-template-columns: auto minmax(286px, 300px) minmax(1080px, 1140px) auto;
  }
}

.side-section {
  width: 100%;

  @screen xs {
    max-width: 300px;
  }

  @screen xl {
    margin-left: auto;
  }
}

.main-section {
  @screen sm {
    max-width: 1140px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  @screen xxl {
    margin-left: 0;
  }
}

#fp-nav.fp-right {
  top: auto !important;
  bottom: 0;
  right: 0 !important;
  background: rgba(255,255,255,0.7);
  border-radius: 8px 0 0 8px;
}

#fp-nav ul li, .fp-slidesNav ul li {
  width: 8px !important;
  height: 8px !important;
  margin: 6px 4px !important;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
  @apply bg-grey-90 opacity-75;
}

#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span {
  width: 8px !important;
  height: 8px !important;
  margin: -4px 0 0 -4px !important;
}

.fp-overflow {
  height: 100%;
}
